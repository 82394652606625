import HeaderTripadvisorLogo from "@components/header/HeaderTripadvisorLogo";
import ExitButton from "@components/icons/ExitButton";
import PhoneIcon from "@components/icons/PhoneIcon";
import {
  IMenuItemResponse,
  ITopNavigationResponse,
} from "@customTypes/commonResponses";
import useAppContext from "@hooks/useAppContext";
import Link from "next/link";
import HeaderMenuItem from "./HeaderMenuItem";

interface IMObileNavModalProps {
  topNavigation: ITopNavigationResponse;
  menuItems: IMenuItemResponse[];
}

function MobileNavModal({
  topNavigation,
  menuItems,
}: IMObileNavModalProps): JSX.Element {
  const appContext = useAppContext();

  const phoneLink = "tel:" + topNavigation.Phone_Action;

  const closeParent = (): void => {
    appContext?.setShowHeaderMenuMobile(false);
  };
  const phoneStroke = "stroke-white";

  return (
    <div
      className={`fixed left-0 top-0 z-50 flex h-[100%] w-[100%] flex-col justify-between overflow-auto bg-white ${
        appContext.showHeaderMenuMobile ? "block" : "hidden"
      } desktop:hidden`}
    >
      <div className="z-100 sticky top-0 bg-white p-[0.875rem]">
        <ExitButton
          onClick={() => {
            closeParent();
          }}
        />
      </div>
      <div className="flex h-full flex-col gap-2 px-[0.875rem]">
        <ul>
          {menuItems.map((item, index) => (
            <HeaderMenuItem
              isMobile={true}
              isLastItem={index === menuItems.length - 1}
              isLogged={false}
              isModalOpened={appContext?.showHeaderMenuMobile}
              key={item.id}
              item={item}
            />
          ))}
        </ul>

        {topNavigation?.Show_Slogan && topNavigation?.TripAdvisor_Rating && (
          <HeaderTripadvisorLogo
            tripadvisorLogo={topNavigation?.TripAdvisor_Rating}
          />
        )}
        <p className="m-auto mb-4 flex h-[17px] w-[326px] justify-center text-p13">
          Customized Costa Rica Vacations, since 1999.
        </p>

        <Link className="pb-[0.875rem]" href={phoneLink}>
          <div className="w-full bg-white desktop:bg-transparent">
            <div className="primary-button-color-scheme flex h-[58px] w-full items-center justify-center rounded-full px-4 font-normal">
              <div className=" h-[1.125rem] w-[1.125rem]">
                <PhoneIcon phoneStroke={phoneStroke} />
              </div>
              &nbsp;{topNavigation.Phone_Visible}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default MobileNavModal;

import { onEnterDown } from "@components/utils/accessibilityHelpers";
import useAppContext from "@hooks/useAppContext";

const BurgerMenu = (): JSX.Element => {
  const appContext = useAppContext();

  const onClickHandler = (): void => {
    appContext?.setShowHeaderMenuMobile(true);
  };

  return (
    <div
      className={`flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-2xl bg-blue-10 hover:bg-blue-20 desktop:h-[40px] desktop:w-fit desktop:rounded-full desktop:px-4`}
      onClick={onClickHandler}
      onKeyDown={(e) => {
        onEnterDown(e, onClickHandler);
      }}
      tabIndex={0}
      aria-label="Open menu"
      title="Open menu"
    >
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.1084 16.2415H18.1084"
          stroke="#10247E"
          strokeWidth="2.4"
          strokeLinecap="round"
        />
        <path
          d="M2.1084 9H18.1084"
          stroke="#10247E"
          strokeWidth="2.4"
          strokeLinecap="round"
        />
        <path
          d="M2.1084 1.75854H18.1084"
          stroke="#10247E"
          strokeWidth="2.4"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default BurgerMenu;
